import * as React from "react";
import { connect } from "react-redux";
import { DefaultButton, PrimaryButton, IconButton, MessageBar, MessageBarType, ContextualMenu, Icon, Link, Modal, IDragOptions } from "office-ui-fabric-react/lib";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import * as toastr from "toastr";
import SettlementInfo from "./settlementInfo";
import WaterUsageSection from "./waterUsage";
import Modals from "./modals";
import SettlementInvalidMatter from "./settlementInvalidMatter";
import { ModalIDs, apportionmentOption, periodOptions, stateName, errorMessages } from "./common";
import * as CONSTANTS from "containers/calculators/settlement/redux/constants";
import {
    generatePDF,
    generateStatementOfAccount,
    changeState,
    clearSettlementState,
    saveSettlementMatter,
    getSettlementMatter,
    savePDF,
    deleteSettlementMatter
} from "containers/calculators/settlement/redux/actions";
// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    SettlementMatterViewModel,
    SettlementInfo as SettlementInfoViewModel,
    ActionstepMatter,
    MatterDetails,
    ActionstepMatterInfo,
    ActionstepDocument,
} from "utils/wcaApiTypes";
import { AppState } from "app.types";
import "./settlementCalculator.css";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { formatToLocaleDateString, escapeRegExp, toFormatLocalString, showAdditionalFields, getNumberOfDays, convertStringToDate, formatToLocaleDateTimeString, validateModalData, reorderDragDropList, QldVendorSellerUtils, isQld } from './Helper';
// import CalculatorAlert from "./calculatorAlert";
import {
    generateUIData,
    // waterAdjustmentCalculation
} from './calculation';
import { IState, ModifiedActionstepData } from './settlementCalculatorInterface';
import PayeeInfo from "./payeeInfo";
import AdditionalRequirementInfo from "./AdditionalRequirementInfo";
import SourceOfFundsInfo from "./sourceOfFundInfo";
import OurRequirementInfo from "./OurRequirementInfo";
import Tools from "utils/tools";
import FeesExplained from "./feesExplained";

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const typeOfStatement = (stateOfCountry: string, useAdvancedCalculator: boolean) => {
    return useAdvancedCalculator ? [
        { key: "VendorStatement", text: `${QldVendorSellerUtils(stateOfCountry)} Statement` },
        { key: "PurchaserStatement", text: "Purchaser Statement" },
        { key: "AdjustmentStatement", text: "Adjustment Statement" },
        { key: "ClientSettlementStatement", text: "Client Settlement Statement" },
        { key: "SettlementStatement", text: "Settlement Statement" },
    ] : [
        { key: "AdjustmentStatement", text: "Adjustment Statement" },
        { key: "ClientSettlementStatement", text: "Client Settlement Statement" }
    ];
}

enum PdfAction {
    generatePdf,
    savePdfToActionstep,
    generateStatementOfAccount
}

export class SettlementCalculator extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            matterDetails: new MatterDetails(),
            matterInfo: null,
            actionstepData: new ActionstepMatter(),
            modifiedActionstepData: null,
            showModal: null,
            adjustments: [],
            fees: [],
            additionalRequirements: [],
            payees: [],
            sourceOfFunds: [],
            ourRequirements: [],
            showWaterUsage: false,
            waterUsagesToShow: [],
            refresh: false,
            selectedIndex: -1,
            lc: "",
            settlementData: new SettlementInfoViewModel(),
            orgConnected: null,
            includeAdditionalCostsInTotal: false,
            isWaiting: false,
            hasError: null,
            dataInputError: "",
            needConfirmation: false,
            isInvalidMatter: false,
            savePDFSuccess: false,
            actionstepPDF: new ActionstepDocument(),
            loadData: null,
            contractPriceGstEnabled: false
        };

        this.saveModal = this.saveModal.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    updatedState: any = {};
    showWaterUsages: any = {};

    _dragOptions: IDragOptions = {
        moveMenuItemText: "Move",
        closeMenuItemText: "Close",
        dragHandleSelector: ".modal-header",
        menu: ContextualMenu,
    };

    public componentDidMount(): void {
        this.loadActionstepMatter();
    }

    private onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        let r = reorderDragDropList(this.state.adjustments, result.source.index, result.destination.index);
        this.setState({
            adjustments: r
        });

        // saving after reordering
        // this.save({ ...this.state, adjustments: r }, false);
    }

    addAustralianTaxation = () => {
        let payees = [...this.state.settlementData.payees!];
        const purchasePrice = this.state.settlementData.adjustments![0].value["price"];

        if (purchasePrice >= 750000) {

            let isPresent = false;

            for (let indexNo = 0; payees.length > indexNo; indexNo++) {

                if (payees[indexNo].value["description"] === "Australian Taxation Office") {
                    isPresent = true;
                    break;
                }
            }

            if (!isPresent) {

                let totalTax = (purchasePrice * 12.5) / 100;

                this.updatedState = {
                    description: "Australian Taxation Office",
                    amount: totalTax,
                    fundType: "",
                    fundsSource: "",
                    rowNumber: 0
                }

                let payee = {
                    value: Object.assign({}, this.updatedState),
                    type: ModalIDs.payeeDetails,
                }

                payees.push(payee);
            }
        }

        return payees;
    }

    private toggleGstForContractPrice = () => {
        if (this.state.adjustments) {
            let gstApplicable = this.state.contractPriceGstEnabled;
            this.setState({
                adjustments: this.state.adjustments.map(adjustment => {
                    if (adjustment["type"].toLowerCase() === "contract price") {
                        gstApplicable = !gstApplicable;
                        adjustment.gstApplicable = gstApplicable;
                        return adjustment;
                    } else {
                        return adjustment;
                    }
                }),
                contractPriceGstEnabled: gstApplicable
            });
        }
    }

    public shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        const { loadData } = this.state;
        if (loadData !== null) {
            this.loadSettlementData(loadData);
        }
        return true;
    }

    private loadActionstepMatter(): void {
        let adjustments = [
            {
                type: "Contract Price",
                value: {
                    price: 0,
                    deposit: 0,
                },
            },
        ];

        this.setState({
            adjustments: adjustments,
        });

        const { actionstepContext } = this.props;

        if (
            actionstepContext === undefined ||
            actionstepContext.matterContext === undefined ||
            // TODO: terms aren't needed here, update context to simplify and remove these checks
            actionstepContext.termsEverAccepted === undefined ||
            actionstepContext.latestTermsAccepted === undefined
        ) {
            this.setState({
                isInvalidMatter: true,
            });

            return;
        }

        // TODO: refactor this out.
        const matterInfo = new ActionstepMatterInfo({
            orgKey: actionstepContext.orgKey,
            matterId: actionstepContext.matterContext?.id,
            termsEverAccepted: actionstepContext.termsEverAccepted,
            latestTermsAccepted: actionstepContext.latestTermsAccepted,
        });

        this.setState({
            matterInfo,
        });
        // this.props.getReleaseFee("QLD");
        this.props.getSettlementMatter(matterInfo);
    }

    public async loadSettlementData(useNewValue = false): Promise<void> {
        const { settlementData, modifiedActionstepData } = this.state;

        const matterDetails: MatterDetails =
            !useNewValue && settlementData.matterDetails
                ? settlementData.matterDetails
                : this.state.matterDetails;

        matterDetails.adjustmentDate = new Date(matterDetails.adjustmentDate);
        matterDetails.settlementDate = new Date(matterDetails.settlementDate);

        let adjustments: { [key: string]: any }[] = settlementData.adjustments === undefined ? [] : settlementData.adjustments.map((adjustment, index) => {
            let newValue: any = {};

            if (adjustment.type === "Contract Price") {
                if (useNewValue) {
                    newValue = {
                        price: modifiedActionstepData!.price.value,
                        deposit: modifiedActionstepData!.deposit.value,
                    };
                } else {
                    newValue = adjustment.value;
                }
            } else {
                Object.keys(adjustment.value).forEach((key) => {
                    if (adjustment.value[key] === null && !key.includes("Date")) {
                        adjustment.value[key] = 0;
                    }

                    if (adjustment.value[key] && (key === "from" || key === "to" || key.includes("Date"))) {
                        newValue[key] = convertStringToDate(adjustment.value[key]);
                    } else {
                        newValue[key] = adjustment.value[key];
                    }
                });
            }

            return {
                ...adjustment,
                value: newValue,
                order: index,
            };
        }).sort((l: { [key: string]: any }, r: { [key: string]: any }) => l.order > r.order ? 1 : l.order === r.order ? 0 : -1);

        let additionalRequirements = settlementData.additionalRequirements === undefined
            ? []
            : settlementData.additionalRequirements;
        let payees = settlementData.payees === undefined ? [] : settlementData.payees;

        if (payees !== undefined && !("australianTaxationOfficeDeleted" in this.state.settlementData.additionalInfo!) || this.state.settlementData.additionalInfo!["australianTaxationOfficeDeleted"] === false) {
            payees = this.addAustralianTaxation();
        }

        let ourRequirements = settlementData.ourRequirements === undefined ? [] : settlementData.ourRequirements;
        let sourceOfFunds = settlementData.sourceOfFunds === undefined ? [] : settlementData.sourceOfFunds;

        let fees = settlementData.fees === undefined
            ? []
            : settlementData.fees.map((fee) => {
                let newValue: any = {};
                Object.keys(fee.value).forEach((key) => {
                    if (key === "from" || key === "to") {
                        newValue[key] = convertStringToDate(fee.value[key]);
                    } else {
                        newValue[key] = fee.value[key];
                    }
                });

                return {
                    ...fee,
                    value: newValue,
                };
            });
        
        let gstApplicable = adjustments.find(adjustment => adjustment.type.toLowerCase() === "contract price")?.["gstApplicable"];

        await this.setState({
            matterDetails: MatterDetails.fromJS(matterDetails),
            adjustments,
            contractPriceGstEnabled: gstApplicable,
            additionalRequirements,
            payees,
            ourRequirements,
            sourceOfFunds,
            fees,
            needConfirmation: false,
            loadData: null,
        });

        // run this to force the adjustDays value to be recalculated again and subsequently the result of the calculator
        if (useNewValue) {
            this.updateAdjustmentDates();
        }

        this.props.clearSettlementState();
        this.props.changeState(matterDetails.state || stateName.VIC);
    }

    private payeeCheckboxHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const { id, checked } = event.target;

        if (id === "allCheckedPayee") {
            let payees = this.state.payees.map((payee) => { return { ...payee, isChecked: checked } });
            this.setState({
                payees: payees
            })
        }
        else {
            let rowIndex = Number(id);
            let payees = this.state.payees.map((payee, index) => index === rowIndex ? { ...payee, isChecked: checked } : payee);
            this.setState({
                payees: payees
            })
        }
    }

    private additionalRequirementCheckboxHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const { id, checked } = event.target;

        if (id === "allCheckedAdditionalRequirement") {
            let additionalRequirements = this.state.additionalRequirements.map((additionalRequirement) => { return { ...additionalRequirement, isChecked: checked } });
            this.setState({
                additionalRequirements: additionalRequirements
            })
        }
        else {
            let rowIndex = Number(id);
            let additionalRequirements = this.state.additionalRequirements.map((additionalRequirement, index) => index === rowIndex ? { ...additionalRequirement, isChecked: checked } : additionalRequirement);
            this.setState({
                additionalRequirements: additionalRequirements
            })
        }
    }

    static getDerivedStateFromProps(
        nextProps: IProps,
        prevState: IState
    ): IState {
        let nextState = {} as IState;

        if (nextProps.gotResponse === true) {
            switch (nextProps.requestType) {
                case CONSTANTS.GENERATE_PDF_REQUESTED:
                case CONSTANTS.GENERATE_STATEMENT_OF_ACCOUNT_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    } else {
                        //Generate PDF Succeed
                    }
                    break;

                case CONSTANTS.SAVE_PDF_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    } else {
                        nextState = {
                            ...nextState,
                            actionstepPDF: nextProps.actionstepPDF,
                            savePDFSuccess: true,
                        };
                    }
                    break;

                case CONSTANTS.GET_SETTLEMENT_MATTER_REQUESTED:
                    if (nextProps.success === true) {
                        if (nextProps.settlementMatter !== undefined) {
                            let settlementMatter: SettlementMatterViewModel =
                                nextProps.settlementMatter;

                            let actionstepData: ActionstepMatter | any =
                                settlementMatter.actionstepData || new ActionstepMatter();
                            let settlementData: SettlementInfoViewModel =
                                settlementMatter.settlementData ||
                                new SettlementInfoViewModel();
                            let matterDetails: MatterDetails | any =
                                settlementData.matterDetails || new MatterDetails();

                            if (settlementData.additionalInfo) {
                                settlementData.additionalInfo["showAdjustment"] = settlementData.additionalInfo["showAdjustment"] ?? true;
                            }

                            let modifiedActionstepData: ModifiedActionstepData =
                                {} as ModifiedActionstepData;
                            let newMatterDetails: MatterDetails | any = new MatterDetails();
                            let hasChangedData: boolean = false;
                            let isNew: boolean = settlementMatter.version === 0;

                            let labelsPerKeys: { [index: string]: string } = {
                                matterRef: "Matter ID",
                                matter: "Actionstep Matter Name",
                                price: "Contract Price",
                                deposit: "Deposit",
                                property: "Property",
                                adjustmentDate: "Adjustment Date",
                                settlementDate: "Settlement Date",
                                settlementPlace: "Settlement Place",
                                settlementType: "Settlement Type",
                                settlementTime: "Settlement Time",
                                state: "State",
                                conveyType: "Conveyancing Type",
                            };

                            nextState = {
                                ...nextState,
                                orgConnected: true,
                            };

                            Object.keys(actionstepData).forEach((key) => {
                                if (actionstepData[key] !== undefined) {
                                    let value = actionstepData[key],
                                        changed = false,
                                        oldValue = null,
                                        displayValue = "";

                                    if (key === "adjustmentDate" || key === "settlementDate") {
                                        value = convertStringToDate(value);
                                    }

                                    if (matterDetails[key] !== undefined) {
                                        if (!isNew) {
                                            if (
                                                key === "adjustmentDate" ||
                                                key === "settlementDate"
                                            ) {
                                                if (matterDetails[key] === null) {
                                                    matterDetails[key] = new Date();
                                                }
                                                let date = new Date(matterDetails[key]);

                                                if (
                                                    date.getFullYear() !== value.getFullYear() ||
                                                    date.getMonth() !== value.getMonth() ||
                                                    date.getDate() !== value.getDate()
                                                )
                                                    changed = true;

                                                displayValue = value.toDateString();
                                                oldValue = date.toDateString();
                                            } else if (matterDetails[key] !== value) {
                                                changed = true;

                                                displayValue = value;
                                                oldValue = matterDetails[key];
                                            }
                                        }
                                    } else {
                                        if (!isNew) {
                                            let adjustment = settlementData.adjustments![0];
                                            if (adjustment.value[key] === null) {
                                                adjustment.value[key] = 0;
                                            }

                                            if (adjustment.value[key] !== value) {
                                                changed = true;
                                            }

                                            displayValue =
                                                "$" +
                                                toFormatLocalString(value, "en-AU", 2, 2);
                                            oldValue =
                                                "$" +
                                                toFormatLocalString(adjustment.value[key], "en-AU", 2, 2);
                                        }
                                    }

                                    hasChangedData = hasChangedData || changed;

                                    if (key !== "price" && key !== "deposit") {
                                        newMatterDetails[key] = value;
                                    }

                                    modifiedActionstepData[key] = {
                                        value: value,
                                        oldValue: oldValue,
                                        displayValue: displayValue,
                                        label: labelsPerKeys[key],
                                        update: false,
                                        changed: changed,
                                    };
                                }
                            });

                            nextState = {
                                ...nextState,
                                matterDetails: newMatterDetails,
                                modifiedActionstepData: modifiedActionstepData,
                                settlementData: settlementData,
                            };

                            if (isNew) {
                                settlementData.adjustments!.push({
                                    type: "Contract Price",
                                    value: {
                                        price: actionstepData.price,
                                        deposit: actionstepData.deposit,
                                    },
                                });
                                settlementData.matterDetails = undefined;

                                nextState = {
                                    ...nextState,
                                    settlementData,
                                    hasError: false,
                                    loadData: false,
                                };
                            } else if (hasChangedData) {
                                nextState = {
                                    ...nextState,
                                    needConfirmation: true,
                                    hasError: false,
                                };
                            } else {
                                nextState = {
                                    ...nextState,
                                    loadData: true,
                                };
                            }
                        }
                    } else {
                        if (nextProps.actionstepContext === undefined || nextProps.actionstepContext.matterContext === undefined) {
                            nextState = {
                                ...nextState,
                                orgConnected: false,
                                hasError: false,
                            };
                        } else {
                            toastr.error(
                                nextProps.error!.message || "Unexpected Error occured..."
                            );
                            nextState = {
                                ...nextState,
                                hasError: true,
                            };
                        }
                    }
                    break;

                case CONSTANTS.SAVE_SETTLEMENT_MATTER_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    } else {
                    }

                    break;

                case CONSTANTS.DELETE_SETTLEMENT_MATTER_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    } else {
                        nextState = {
                            ...nextState,
                            adjustments: [prevState.adjustments![0]],
                            fees: [],
                            additionalRequirements: [],
                            payees: [],
                            sourceOfFunds: [],
                            ourRequirements: [],
                            showWaterUsage: false,
                            settlementData: new SettlementInfoViewModel({
                                additionalInfo: {
                                    showPayees: true,
                                    showSourceOfFunds: true,
                                    showAdditionalRequirements: true,
                                    showFeesExplained: true,
                                    showOurRequirements: true,
                                    showAdjustment: true
                                }
                            })
                        };
                    }

                    break;

                default:
                    return nextState;
            }

            nextProps.clearSettlementState();
        }

        return nextState;
    }

    public toggleIncludeAdditionalRequirementsInTotal(checked: boolean): void {
        this.setState({
            includeAdditionalCostsInTotal: checked,
        });
    }

    public render(): JSX.Element {
        const {
            matterDetails,
            adjustments,
            additionalRequirements,
            payees,
            sourceOfFunds,
            ourRequirements,
            additionalInfo,
            waterUsage,
        } = generateUIData(this.state);

        const {
            actionstepData,
            modifiedActionstepData,
            orgConnected,
            hasError,
            dataInputError,
            isInvalidMatter,
            actionstepPDF,
            showModal,
            savePDFSuccess,
            needConfirmation,
            isWaiting,
        } = this.state;

        const {
            payeeDebit,
            payeeCredit,
            additionalDebit,
            additionalCredit,
            unallocated,
            additionalUnallocated,
            unallocatedFund,
            payeeTotal,
            fundTotal,
            hasWaterUsage,
            payableByVendorTotal,
            payableByPurchaserTotal,
            sumOfPlusLessAdjustmentAmount,
            balanceDueToTheVendorAtSettlement,
            showAdditionalRequirements,
            showPayees,
            showSourceOfFunds,
            showOurRequirements,
            statementType,
            adjustmentGstTotal,
            showFeesExplained,
            feesExplainedDescription,
            showAdjustment,
        } = additionalInfo!;

        const isVendor = matterDetails!.conveyType === "vendor";
        const State = this.props.state;

        return (
            <div className="settlement-calculator">
                {isInvalidMatter ? (
                    <SettlementInvalidMatter />
                ) : orgConnected === true ? (
                    <><div className="wrapper-content animated fadeIn vertical-container">
                        <div className="ibox">
                            <div className="ibox-title">
                                <div className="ms-Grid-row state-row">
                                    <div className="ms-Grid-col ms-sm5 modal-label">
                                        <b>Type of statement to be prepared?</b>
                                    </div>
                                    <div className="ms-Grid-col ms-sm7">
                                        <Dropdown
                                            placeholder="Select Statement"
                                            data-cy="statementType_select"
                                            label=""
                                            // selectedKey={this.state.statementType}
                                            defaultSelectedKey={statementType}
                                            options={typeOfStatement(this.props.stateOfCountry, this.props.useAdvancedCalculator)}
                                            // selectedKey={this.state?.matterDetails?.conveyType}
                                            onChange={(ev, newItem) =>
                                                this.updateAdjustmentType(newItem!.key, "statementType")
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="ibox-content">
                                <div className="section">
                                    <div className="section-body">
                                        <div className="ms-Grid-row state-row">
                                            <div className="ms-Grid-col ms-sm2">
                                                <IconButton
                                                    className="topaz-blue-icon-button"
                                                    data-automation-id="save_button"
                                                    data-cy="matter_detail_info"
                                                    onClick={() => this.showModal(ModalIDs.matterDetails, { index: -1, })}
                                                    allowDisabledFocus={true}
                                                    iconProps={{ iconName: "ChevronRightSmall" }}
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm10">
                                                <div className="ms-Grid" dir="ltr">
                                                    <div className="ms-Grid-row detail-row">
                                                        <div className="ms-Grid-col ms-sm3">
                                                            <b>Matter</b>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm9">
                                                            {(matterDetails &&
                                                                matterDetails.matter &&
                                                                matterDetails.matter) || ""}
                                                        </div>
                                                    </div>

                                                    <div className="ms-Grid-row detail-row">
                                                        <div className="ms-Grid-col ms-sm3">
                                                            <b>Property</b>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm9">
                                                            {matterDetails && matterDetails.property}
                                                        </div>
                                                    </div>

                                                    <div className="ms-Grid-row detail-row">
                                                        <div className="ms-Grid-col ms-sm3">
                                                            <b>Adjustment Date</b>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm9">
                                                            {(matterDetails && matterDetails.adjustmentDate && formatToLocaleDateString(matterDetails.adjustmentDate)) || ""}
                                                        </div>
                                                    </div>

                                                    <div className="ms-Grid-row detail-row">
                                                        <div className="ms-Grid-col ms-sm3">
                                                            <b>Settlement Date</b>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm9">
                                                            {(matterDetails && matterDetails.settlementDate && formatToLocaleDateString(matterDetails.settlementDate)) || ""}
                                                        </div>
                                                    </div>
                                                    <div className="detail-row ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm3">
                                                            <b>Settlement Type</b>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm9">
                                                            {matterDetails && matterDetails.settlementType}
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-row detail-row">
                                                        <div className="ms-Grid-col ms-sm3">
                                                            <b>Settlement Place</b>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm9">
                                                            {matterDetails && matterDetails.settlementPlace}
                                                        </div>
                                                    </div>

                                                    <div className="detail-row ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm3">
                                                            <b>Settlement Time</b>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm9">
                                                            {matterDetails && matterDetails.settlementTime}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="section">
                                    <div className="dual-section-header">
                                        <span className="left-align-section-header">
                                            {this.props.useAdvancedCalculator && <FormControlLabel label='GST Applies on Contract/Purchase Price' className='mui-font-override' control={<Checkbox
                                                checked={this.state.contractPriceGstEnabled}
                                                color='primary'
                                                size='small'
                                                onChange={this.toggleGstForContractPrice} />} />}
                                        </span>
                                        <span className="right-align-section-header">
                                            Show/Hide Adjustment
                                            <IconButton
                                                className="button show-hide-button"
                                                data-automation-id="save_button"
                                                onClick={() => this.toggleSection("Adjustment")}
                                                allowDisabledFocus={true}
                                                iconProps={{
                                                    iconName: showAdjustment
                                                        ? "CaretDownSolid8"
                                                        : "CaretUpSolid8",
                                                    style: { fontSize: 10 },
                                                }}
                                            />
                                        </span>
                                    </div>
                                    
                                    {statementType === "SettlementStatement" &&
                                        <div className="SettlementStatement">
                                            {adjustments &&
                                                adjustments.map((adjustment, index) => {
                                                    if (this.state.settlementData.additionalInfo!['showAdjustment'] && adjustment["type"].toLowerCase() === "contract price") {
                                                        return (
                                                            <SettlementInfo
                                                                isVendor={isVendor}
                                                                toggleWaterUsage={(item) => this.setShowingWaterUsages(item)}
                                                                key={"adjustment_" + index}
                                                                item={adjustment}
                                                                index={index}
                                                                additionalInfo={additionalInfo}
                                                                adjustmentDate={
                                                                    matterDetails && matterDetails.adjustmentDate
                                                                }
                                                                showModal={(modalID, additionalInfo) =>
                                                                    this.showModal(modalID, additionalInfo)
                                                                }
                                                                payeeCheckboxHandler={(event) =>
                                                                    this.payeeCheckboxHandler(event)
                                                                }
                                                                additionalRequirementCheckboxHandler={(event) =>
                                                                    this.additionalRequirementCheckboxHandler(event)
                                                                }
                                                                draggable={true}
                                                                conveyType={statementType}
                                                            />
                                                        );
                                                    }
                                                    else {
                                                        return "";
                                                    }
                                                })}
                                        </div>}

                                    {this.state.settlementData.additionalInfo!['showAdjustment'] && <div className={statementType === "SettlementStatement" ? "SettlementStatementBorder" : ""}>
                                        {statementType !== "SettlementStatement" && <div className="section-body animated fadeIn">
                                            <div className="ms-Grid-row additional-header">
                                                <div className="ms-Grid-col ms-sm8 ms-smPush2">
                                                    <b className="header-text">ADJUSTMENTS</b>
                                                </div>
                                            </div>
                                        </div>}
                                        {!isQld(this.state.matterDetails.state) &&
                                            <div className="ms-Grid-row" dir="rtl">
                                                <div className="ms-Grid-col ms-sm2 right-align">
                                                    <span><b>Payable by<br />Purchaser</b> </span>
                                                </div>
                                                <div className="ms-Grid-col ms-sm2 center-align">
                                                    <span><b>Payable by<br />{QldVendorSellerUtils(this.props.stateOfCountry)}</b> </span>
                                                </div>
                                            </div>
                                        }

                                        <div className="section-body">
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                <Droppable droppableId="settlement-info">
                                                    {(provided: any, snapshot: any) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {adjustments &&
                                                                adjustments.map((adjustment, index) => {
                                                                    if (adjustment["type"].toLowerCase() !== "contract price") {
                                                                        return (
                                                                            <Draggable key={index} draggableId={"1-" + index} index={index}>
                                                                                {(provided: any, snapshot: any) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        <SettlementInfo
                                                                                            isVendor={isVendor}
                                                                                            toggleWaterUsage={(item) => this.setShowingWaterUsages(item)}
                                                                                            key={"adjustment_" + index}
                                                                                            item={adjustment}
                                                                                            index={index}
                                                                                            additionalInfo={additionalInfo}
                                                                                            adjustmentDate={
                                                                                                matterDetails && matterDetails.adjustmentDate
                                                                                            }
                                                                                            showModal={(modalID, additionalInfo) =>
                                                                                                this.showModal(modalID, additionalInfo)
                                                                                            }
                                                                                            payeeCheckboxHandler={(event) =>
                                                                                                this.payeeCheckboxHandler(event)
                                                                                            }
                                                                                            additionalRequirementCheckboxHandler={(event) =>
                                                                                                this.additionalRequirementCheckboxHandler(event)
                                                                                            }
                                                                                            draggable={true}
                                                                                            conveyType={statementType}
                                                                                        />

                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        );
                                                                    }
                                                                    else {
                                                                        return "";
                                                                    }
                                                                })}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            {
                                                (!isQld(State)) &&
                                                <div>
                                                    <div className="ms-Grid-row state-row">
                                                        <div className="ms-Grid-col ms-sm2"></div>
                                                        <div className="ms-Grid-col ms-sm10">
                                                            <div className="ms-Grid" dir="ltr">
                                                                <div className="ms-Grid-row detail-row">
                                                                    <div className="ms-Grid-col ms-sm7 ms-smPush5 add-label">
                                                                        <b>Totals</b>
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm3 center-align">
                                                                        <b>{toFormatLocalString(payableByVendorTotal, "en-AU", 2, 2)}</b>
                                                                    </div>

                                                                    <div className="ms-Grid-col ms-sm2 price-info">
                                                                        {
                                                                            <span data-cy="contract_debit_value">
                                                                                <b>{toFormatLocalString(payableByPurchaserTotal, "en-AU", 2, 2)}</b>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="ms-Grid-row detail-row">
                                                                    <div className="ms-Grid-col ms-sm7 ms-smPush5 add-label">
                                                                        <span>Less Amount Payable By {QldVendorSellerUtils(this.props.stateOfCountry)}</span>
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm3 center-align"></div>
                                                                    <div className="ms-Grid-col ms-sm2 price-info">
                                                                        {toFormatLocalString(payableByVendorTotal, "en-AU", 2, 2)}
                                                                    </div>
                                                                </div>
                                                                {statementType === "SettlementStatement" && adjustmentGstTotal > 0 && 
                                                                    <div className="ms-Grid-row detail-row">
                                                                    <div className="ms-Grid-col ms-sm7 ms-smPush5 add-label">
                                                                        <span>Plus GST on Adjustment</span>
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm3 center-align"></div>
                                                                    <div className="ms-Grid-col ms-sm2 price-info">
                                                                        {toFormatLocalString(adjustmentGstTotal, "en-AU", 2, 2)}
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm12 separator">
                                                        --------------
                                                    </div>
                                                </div>
                                            }

                                            <div className="ms-Grid-row state-row">
                                                <div className="ms-Grid-col ms-sm2">
                                                    <IconButton
                                                        className="button rounded-blue-icon-button"
                                                        data-automation-id="save_button"
                                                        data-cy="add_adjustment_button"
                                                        onClick={() => this.showModal(ModalIDs.addAdjustment, { index: -1, })}
                                                        allowDisabledFocus={true}
                                                        iconProps={{ iconName: "Add", style: { fontSize: 15, fontWeight: 900, }, }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm10">
                                                    <div className="ms-Grid" dir="ltr">
                                                        <div className="ms-Grid-row detail-row">
                                                            <div className={"ms-Grid-col add-label " + (statementType === "SettlementStatement" ? "ms-sm4" : "ms-sm7")}>
                                                                Click + to Add an Adjustment
                                                            </div>
                                                            <div className={"ms-Grid-col " + (statementType === "SettlementStatement" ? "ms-sm6" : "ms-sm3")}>
                                                                <b>{statementType === "SettlementStatement" ? `BALANCE DUE TO THE ${QldVendorSellerUtils(this.props.stateOfCountry).toUpperCase()} AT SETTLEMENT` : "TOTAL ADJUSTMENTS"}</b>
                                                            </div>

                                                            <div className="ms-Grid-col ms-sm2 price-info">
                                                                {
                                                                    <b data-cy="contract_debit_value">
                                                                        {/* ${statementType === "SettlementStatement" && this.state.matterDetails.state === stateName.QLD ? toFormatLocalString(additionalInfo!["balanceDueToTheVendorAtSettlement"], "en-AU", 2, 2) : toFormatLocalString(sumOfPlusLessAdjustmentAmount, "en-AU", 2, 2)} */}
                                                                        ${statementType === "SettlementStatement" ? toFormatLocalString(additionalInfo!["balanceDueToTheVendorAtSettlement"], "en-AU", 2, 2) : toFormatLocalString(sumOfPlusLessAdjustmentAmount, "en-AU", 2, 2)}
                                                                    </b>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div >
                                            </div >

                                            {this.props.useAdvancedCalculator && <div className="ms-Grid-row state-row">
                                                <div className="ms-Grid-col ms-sm2">
                                                    <IconButton
                                                        className="button rounded-blue-icon-button"
                                                        data-automation-id="save_button"
                                                        data-cy="add_header_button"
                                                        onClick={() => this.showModal(ModalIDs.addUpdateHeader, { index: -1 })}
                                                        allowDisabledFocus={true}
                                                        iconProps={{ iconName: "Add", style: { fontSize: 15, fontWeight: 900, }, }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm10">
                                                    <div className="ms-Grid" dir="ltr">
                                                        <div className="ms-Grid-row detail-row">
                                                            <div className={"ms-Grid-col add-label " + (statementType === "SettlementStatement" ? "ms-sm4" : "ms-sm7")}>
                                                                Click + to Add a Header Row
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div >
                                            </div >}

                                        </div >
                                    </div >}
                                </div>

                                {statementType !== "SettlementStatement" && this.state.settlementData.additionalInfo!['showAdjustment'] &&
                                    <div>
                                        <div className="section">
                                            {adjustments &&
                                                adjustments.map((adjustment, index) => {
                                                    if (adjustment["type"].toLowerCase() === "contract price") {
                                                        return (
                                                            <SettlementInfo
                                                                isVendor={isVendor}
                                                                toggleWaterUsage={(item) => this.setShowingWaterUsages(item)}
                                                                key={"adjustment_" + index}
                                                                item={adjustment}
                                                                index={index}
                                                                additionalInfo={additionalInfo}
                                                                adjustmentDate={
                                                                    matterDetails && matterDetails.adjustmentDate
                                                                }
                                                                showModal={(modalID, additionalInfo) =>
                                                                    this.showModal(modalID, additionalInfo)
                                                                }
                                                                payeeCheckboxHandler={(event) =>
                                                                    this.payeeCheckboxHandler(event)
                                                                }
                                                                additionalRequirementCheckboxHandler={(event) =>
                                                                    this.additionalRequirementCheckboxHandler(event)
                                                                }
                                                                draggable={true}
                                                                conveyType={statementType}
                                                            />
                                                        );
                                                    }
                                                    else {
                                                        return "";
                                                    }
                                                })}
                                        </div>

                                        <PayeeInfo
                                            stateOfCountry={this.props.stateOfCountry}
                                            toggleSection={(payee) => this.toggleSection(payee)}
                                            showModal={(modalID, additionalInfo) => this.showModal(modalID, additionalInfo)}
                                            showPayee={showPayees}
                                            unallocatedFund={unallocated}
                                            isVendor={isVendor}
                                            additionalInfo={additionalInfo}
                                            conveyType={statementType}
                                            payeeCheckboxHandler={(event) =>
                                                this.payeeCheckboxHandler(event)
                                            }
                                            payeeTotal={payeeTotal}
                                            payees={payees}
                                            onReorder={l => this.setState({ payees: l })}
                                        />
                                    </div>
                                }

                                <AdditionalRequirementInfo
                                    toggleSection={(payee) => this.toggleSection(payee)}
                                    showModal={(modalID, additionalInfo) => this.showModal(modalID, additionalInfo)}
                                    iconName={showAdditionalRequirements}
                                    unallocatedFund={unallocated}
                                    isVendor={isVendor}
                                    additionalInfo={additionalUnallocated}
                                    conveyType={statementType}
                                    additionalRequirementCheckboxHandler={(event) =>
                                        this.additionalRequirementCheckboxHandler(event)
                                    }
                                    balanceDueToTheVendorAtSettlement={balanceDueToTheVendorAtSettlement}
                                    additionalRequirements={additionalRequirements}
                                    additionalDebit={additionalDebit}
                                    additionalCredit={additionalCredit}
                                    onReorder={l => this.setState({ additionalRequirements: l })}
                                />

                                {
                                    statementType === "SettlementStatement" &&
                                    <PayeeInfo
                                        stateOfCountry={this.props.stateOfCountry}
                                        toggleSection={(payee) =>
                                            this.toggleSection(payee)
                                        }
                                        showModal={(modalID, additionalInfo) =>
                                            this.showModal(modalID, additionalInfo)
                                        }
                                        showPayee={showPayees}
                                        unallocatedFund={unallocated}
                                        isVendor={isVendor}
                                        additionalInfo={additionalInfo}
                                        conveyType={statementType}
                                        payeeCheckboxHandler={(event) =>
                                            this.payeeCheckboxHandler(event)
                                        }
                                        payeeTotal={payeeTotal}
                                        payees={payees}
                                        onReorder={l => this.setState({ payees: l })}
                                    />
                                }

                                <SourceOfFundsInfo
                                    toggleSection={(payee) => this.toggleSection(payee)}
                                    showModal={(modalID, additionalInfo) => this.showModal(modalID, additionalInfo)}
                                    showSourceOfFund={showSourceOfFunds}
                                    unallocatedFund={unallocatedFund}
                                    isVendor={isVendor}
                                    additionalInfo={additionalInfo}
                                    conveyType={statementType}
                                    payeeCheckboxHandler={(event) =>
                                        this.payeeCheckboxHandler(event)
                                    }
                                    sourceOfFundTotal={fundTotal}
                                    sourceOfFunds={sourceOfFunds}
                                />

                                <OurRequirementInfo
                                    toggleSection={(payee) => this.toggleSection(payee)}
                                    showModal={(modalID, additionalInfo) => this.showModal(modalID, additionalInfo)}
                                    iconName={showOurRequirements}
                                    isVendor={isVendor}
                                    additionalInfo={additionalInfo}
                                    conveyType={statementType}
                                    payeeCheckboxHandler={(event) => this.payeeCheckboxHandler(event)}
                                    ourRequirements={ourRequirements}
                                />

                                {this.props.useAdvancedCalculator && <FeesExplained
                                    toggleSection={(fees) => this.toggleSection(fees)}
                                    showFeesExplained={showFeesExplained}
                                    statementType={statementType}
                                    feesExplainedDescription={feesExplainedDescription}
                                />}

                            </div >

                            <div className="ibox-footer">
                                <PrimaryButton
                                    className="button"
                                    data-automation-id="save_button"
                                    data-cy="save_button"
                                    text="Show/Hide Water Usage"
                                    onClick={() => this.toggleWaterUsage()}
                                    allowDisabledFocus={true}
                                />

                                <PrimaryButton
                                    className="button"
                                    data-automation-id="save_button"
                                    data-cy="save_button"
                                    text="Save"
                                    onClick={() => this.save()}
                                    allowDisabledFocus={true}
                                />

                                {State === stateName.SA && isVendor ? (
                                    <div>
                                        <PrimaryButton
                                            className="button"
                                            data-automation-id="generate_button"
                                            data-cy="generate_button"
                                            text="Generate Vendor PDF"
                                            onClick={() => this.generatePDF(false)}
                                            allowDisabledFocus={true}
                                        />

                                        <PrimaryButton
                                            className="button"
                                            data-automation-id="generate_button"
                                            data-cy="generate_button"
                                            text="Generate Adjustment PDF"
                                            onClick={() => this.generatePDF(true)}
                                            allowDisabledFocus={true}
                                        />
                                    </div>
                                ) : (
                                    <PrimaryButton
                                        className="button"
                                        data-automation-id="generate_button"
                                        data-cy="generate_button"
                                        text="Generate a PDF"
                                        onClick={() => this.generatePDF()}
                                        allowDisabledFocus={true}
                                    />
                                )}

                                <PrimaryButton
                                    className="button"
                                    data-automation-id="save_pdf_to_actionstep"
                                    data-cy="save_pdf_to_actionstep"
                                    text="Save PDF to Actionstep"
                                    onClick={() => this.generatePDF(false, PdfAction.savePdfToActionstep)}
                                    allowDisabledFocus={true}
                                />

                                {this.props.useAdvancedCalculator && <PrimaryButton
                                    className="button"
                                    data-automation-id="generate_statement_of_account"
                                    data-cy="generate_statement_of_account"
                                    text="Generate Statement of Account"
                                    onClick={() => this.generatePDF(false, PdfAction.generateStatementOfAccount)}
                                    allowDisabledFocus={true}
                                />}

                                {this.props.useAdvancedCalculator && <DefaultButton
                                    primary
                                    className="button"
                                    data-automation-id="test"
                                    allowDisabledFocus={true}
                                    persistMenu={true}
                                    text="Email Options"
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onMenuClick={(ev) => { }}
                                    menuProps={{
                                        items: [
                                            {
                                                key: "emailMessage",
                                                text: "Email Cheques to Bank (Outlook Only)",
                                                iconProps: { iconName: "Mail" },
                                                onClick: () => this.sendEmail(),
                                            },
                                            {
                                                key: "calendarEvent",
                                                text: "Email Cheques to OS",
                                                iconProps: { iconName: "System" },
                                                onClick: () => this.sendEmail(),
                                            },
                                        ],
                                        directionalHintFixed: true,
                                    }}
                                />}

                                <DefaultButton
                                    className="button red-button"
                                    data-automation-id="delete_button"
                                    data-cy="delete_button"
                                    text="Delete"
                                    onClick={() => this.delete()}
                                    allowDisabledFocus={true}
                                />
                            </div>
                            {
                                // determine which water usage entry to show, if there is no entry in the waterUsagesToShow array, show all of them
                                this.state.showWaterUsage && hasWaterUsage === true &&
                                (waterUsage ?? [])
                                    .filter(usage => this.state.waterUsagesToShow.length === 0 || this.state.waterUsagesToShow.includes(usage.index))
                                    .map((usage, index) => <WaterUsageSection key={index} waterUsage={usage} state={this.state.matterDetails.state} />)
                            }
                        </div >

                        <div data-cy="modal-header" />
                        <Modal
                            isOpen={needConfirmation}
                            isBlocking={false}
                            onDismiss={() => this.loadSettlementData(false)}
                            className={
                                needConfirmation
                                    ? "animated fadeIn modal settlement-calculator"
                                    : "animated fadeOut modal settlement-calculator"
                            }
                            dragOptions={this._dragOptions}
                        >
                            <div className="modal-header" data-cy="modal-header">
                                <span className="modal-title">Confirm</span>

                                <IconButton
                                    className="modal-close-button"
                                    onClick={() => this.loadSettlementData(false)}
                                    iconProps={{
                                        iconName: "ChromeClose",
                                        style: {
                                            fontSize: 12,
                                            fontWeight: 500,
                                        },
                                    }}
                                />
                            </div>

                            <div className="modal-body">
                                These fields have been changed since the last edit. Would you
                                like to use the new data?
                                <br />
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm4">
                                        <b>Field</b>
                                    </div>
                                    <div className="ms-Grid-col ms-sm4">
                                        <b>Old Value</b>
                                    </div>
                                    <div className="ms-Grid-col ms-sm4">
                                        <b>New Value</b>
                                    </div>
                                </div>
                                {Object.keys(modifiedActionstepData!).map((key) => {
                                    if (modifiedActionstepData![key].changed) {
                                        return (
                                            <div className="ms-Grid-row" key={key}>
                                                <div className="ms-Grid-col ms-sm4">
                                                    {modifiedActionstepData![key].label}
                                                </div>
                                                <div className="ms-Grid-col ms-sm4">
                                                    {modifiedActionstepData![key].oldValue}
                                                </div>
                                                <div className="ms-Grid-col ms-sm4">
                                                    {modifiedActionstepData![key].displayValue}
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>

                            <div className="modal-footer">
                                <DefaultButton
                                    className="button gray-button"
                                    data-automation-id="use_old_button"
                                    data-cy="use_old_button"
                                    text="No"
                                    onClick={() => this.loadSettlementData(false)}
                                    allowDisabledFocus={true}
                                />

                                <PrimaryButton
                                    className="button"
                                    data-automation-id="use_new_button"
                                    data-cy="use_new_button"
                                    text="Yes"
                                    onClick={() => this.loadSettlementData(true)}
                                    allowDisabledFocus={true}
                                />
                            </div>
                        </Modal>

                        <Modal
                            isOpen={isWaiting}
                            isBlocking={true}
                            className={
                                isWaiting !== null
                                    ? "animated fadeIn settlement-calculator"
                                    : "animated fadeOut settlement-calculator"
                            }
                        >
                            <div className="modal-body">
                                <div className="ms-Grid-row loading-modal">
                                    <img
                                        src="/images/Konekta_loading.svg" alt="Logo" height="150" />
                                    <h2>Please wait...</h2>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={savePDFSuccess}
                            isBlocking={false}
                            onDismiss={() => this.closeModal()}
                            className={
                                savePDFSuccess
                                    ? "animated fadeIn settlement-calculator"
                                    : "animated fadeOut settlement-calculator"
                            }
                        >
                            <div className="modal-header">
                                <span className="modal-title">
                                    PDF saved to Actionstep successfully!
                                </span>
                            </div>
                            <div className="modal-body">
                                Your PDF has been saved to Actionstep at the following location:{" "}
                                <Link
                                    rel="noopener noreferrer"
                                    href={actionstepPDF!.url}
                                    target="_blank"
                                    download
                                >
                                    {actionstepPDF!.fileName}
                                </Link>{" "}
                                <br />
                                or{" "}
                                <Link
                                    rel="noopener noreferrer"
                                    href={actionstepPDF!.documentUrl}
                                    target="_blank"
                                >
                                    <b>view in Actionstep</b>
                                </Link>{" "}
                                (This link requires you to be logged in to the related
                                Actionstep org)
                            </div>

                            <div className="modal-footer">
                                <DefaultButton
                                    className="button gray-button"
                                    data-automation-id="close_modal"
                                    data-cy="close_modal"
                                    text="OK"
                                    onClick={() => this.closeModal()}
                                    allowDisabledFocus={true}
                                />
                            </div>
                        </Modal>

                        <Modal
                            isOpen={showModal !== null}
                            isBlocking={false}
                            onDismiss={() => this.closeModal()}
                            className={
                                showModal !== null
                                    ? "animated fadeIn settlement-calculator"
                                    : "animated fadeOut settlement-calculator"
                            }
                            dragOptions={this._dragOptions}
                        >
                            {showModal !== null && (
                                <Modals
                                    modalID={showModal}
                                    closeModal={() => this.closeModal()}
                                    saveModal={this.saveModal}
                                    deleteData={(index, modalID) =>
                                        this.deleteData(index, modalID)
                                    }
                                    updateValue={(newValue, whichValue, needRefresh = false) =>
                                        this.updateValue(newValue, whichValue, needRefresh)
                                    }
                                    useAdvancedCalculator={this.props.useAdvancedCalculator}
                                    matterDetails={this.state.matterDetails}
                                    updatedState={this.updatedState}
                                    index={this.state.selectedIndex}
                                    balanceFunds={balanceDueToTheVendorAtSettlement + payeeDebit - payeeCredit}
                                    actionstepData={actionstepData}
                                    dataInputError={dataInputError}
                                    statewuseReleaseFee={this.state.settlementData.additionalInfo!["statewiseReleaseFee"]}
                                />
                            )}
                        </Modal>
                    </div ></>
                ) : hasError === true ? (
                    <div className="wrapper wrapper-content animated fadeInRight vertical-container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="panel panel-danger">
                                    <div className="panel-heading">
                                        <Icon iconName="StatsCircleExclamation" />{" "}
                                        <span className="m-l-xs">Sorry, something went wrong</span>
                                    </div>
                                    <div className="panel-body p-v-xs">
                                        <p className="text-danger">
                                            Please contact{" "}
                                            <Link
                                                href={
                                                    "mailto:support@konekta.com.au?subject=Settlement%20Calculator"
                                                }
                                            >
                                                {" "}
                                                support@konekta.com.au
                                            </Link>
                                            .
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : hasError === false ? (
                    <div className="wrapper wrapper-content animated fadeInRight vertical-container">
                        <MessageBar messageBarType={MessageBarType.error}>
                            You're not connected to <strong>Konekta</strong>.
                        </MessageBar>
                    </div>
                ) : (
                    <div className="loading-widget">
                        <img src="/images/Konekta_loading.svg" alt="Logo" height="150" />{" "}
                        Loading...
                    </div>
                )
                }
            </div >
        );
    }

    private toggleSection(section: string): void {
        let { settlementData } = this.state;
        if (section === "Payees") {
            settlementData.additionalInfo!['showPayees'] = !settlementData.additionalInfo!['showPayees']
        }
        else if (section === "SourceOfFunds") {
            settlementData.additionalInfo!['showSourceOfFunds'] = !settlementData.additionalInfo!['showSourceOfFunds'];
        }
        else if (section === "AdditionalRequirements") {
            settlementData.additionalInfo!['showAdditionalRequirements'] = !settlementData.additionalInfo!['showAdditionalRequirements'];
        }
        else if (section === "OurRequirements") {
            settlementData.additionalInfo!['showOurRequirements'] = !settlementData.additionalInfo!['showOurRequirements'];
        }
        else if (section === "FeesExplained") {
            settlementData.additionalInfo!['showFeesExplained'] = !settlementData.additionalInfo!['showFeesExplained'];
        }
        else if (section === "Adjustment") {
            settlementData.additionalInfo!['showAdjustment'] = !settlementData.additionalInfo!['showAdjustment'];
        }
        this.setState({
            settlementData: settlementData
        });
    }

    public connectedToActionstep(): void {
        this.setState({
            orgConnected: null,
            hasError: null,
            isInvalidMatter: false,
        });
        this.loadActionstepMatter();
    }

    private async updateAdjustmentType(newValue: any, whichValue: string, needRefresh: boolean = false): Promise<any> {
        // this.updatedState[whichValue] = newValue;

        // const newMatterDetails = this.state.matterDetails;
        // newMatterDetails.conveyType = newValue;

        let { settlementData } = this.state;
        settlementData.additionalInfo!["statementType"] = newValue;
        switch (newValue) {
            case "VendorStatement":
                settlementData.additionalInfo!['showPayees'] = true;
                settlementData.additionalInfo!['showAdditionalRequirements'] = false;
                settlementData.additionalInfo!['showSourceOfFunds'] = false;
                settlementData.additionalInfo!['showOurRequirements'] = true;
                settlementData.additionalInfo!['showFeesExplained'] = false;
                settlementData.additionalInfo!['showAdjustment'] = true;
                break;

            case "ClientSettlementStatement":
            case "PurchaserStatement":
                settlementData.additionalInfo!['showPayees'] = false;
                settlementData.additionalInfo!['showAdditionalRequirements'] = true;
                settlementData.additionalInfo!['showSourceOfFunds'] = true;
                settlementData.additionalInfo!['showOurRequirements'] = false;
                settlementData.additionalInfo!['showFeesExplained'] = false;
                settlementData.additionalInfo!['showAdjustment'] = false;
                break;

            default:
                settlementData.additionalInfo!['showPayees'] = true;
                settlementData.additionalInfo!['showAdditionalRequirements'] = true;
                settlementData.additionalInfo!['showSourceOfFunds'] = true;
                settlementData.additionalInfo!['showOurRequirements'] = true;
                settlementData.additionalInfo!['showFeesExplained'] = false;
                settlementData.additionalInfo!['showAdjustment'] = true;
                break;
        }

        await this.setState({
            settlementData: settlementData,
            // matterDetails: newMatterDetails,
        });
    }

    private updateValue(newValue: any, whichValue: string, needRefresh: boolean = false): void {
        this.updatedState[whichValue] = newValue;

        if (needRefresh) {
            this.setState({
                refresh: !this.state.refresh,
            });
        }
    }

    // this function toggles showing all the water usages on and off
    private toggleWaterUsage() {
        this.setState({
            showWaterUsage: !this.state.showWaterUsage,
            waterUsagesToShow: []
        });
    }

    // this function sets which water usage entry to show at that moment
    private setShowingWaterUsages(index: number) {
        this.setState({
            showWaterUsage: true,
            waterUsagesToShow: [index]
        })
    }

    private deleteData(index: number, modalID: ModalIDs): void {
        let currentState = this.state;
        switch (modalID) {
            case ModalIDs.additionalRequirements:
                let additionalRequirements = [...this.state.additionalRequirements];
                additionalRequirements.splice(index, 1);
                currentState = { ...currentState, additionalRequirements: additionalRequirements }

                break;

            case ModalIDs.payeeDetails:
                let payees = [...this.state.payees];
                let australianTaxationOfficeDeleted = payees[index].value["description"] === "Australian Taxation Office";

                let settlementData = new SettlementInfoViewModel(this.state.settlementData)
                let additionalInfo = { ...settlementData.additionalInfo, australianTaxationOfficeDeleted: australianTaxationOfficeDeleted }
                settlementData.additionalInfo = additionalInfo

                payees.splice(index, 1);
                currentState = {
                    ...currentState,
                    settlementData: settlementData,
                    payees: payees
                };

                break;


            case ModalIDs.sourceOfFunds:
                let funds = [...this.state.sourceOfFunds];
                funds.splice(index, 1);
                currentState = {
                    ...currentState,
                    sourceOfFunds: funds,
                };

                break;

            case ModalIDs.ourRequirements:
                let ourRequirements = [...this.state.ourRequirements];
                ourRequirements.splice(index, 1);
                currentState = {
                    ...currentState,
                    ourRequirements: ourRequirements,
                };

                break;

            case ModalIDs.fee:
                let fees = [...this.state.fees];
                fees.splice(index, 1);
                currentState = {
                    ...currentState,
                    fees: fees,
                };

                break;

            default:
                let adjustments = [...this.state.adjustments!];
                adjustments.splice(index, 1);
                currentState = {
                    ...currentState,
                    adjustments: adjustments,
                };

                break;
        }

        this.setState({
            ...currentState
        });

        // call the save function, passing in the current state so whatever changes is saved in the setState above would be used immediately
        // in the save function
        if (modalID != ModalIDs.addAdjustment.toString()) {
            this.save(currentState);
        }

        this.closeModal();
    }

    private showModal(modalID: ModalIDs, additionalInfo: any = null): void {
        let updatedState = {};

        switch (modalID) {
            case ModalIDs.matterDetails:
                updatedState = Object.assign({}, this.state.matterDetails);
                break;

            case ModalIDs.addAdjustment:
                updatedState = {
                    itemType: "Water Usage",
                };
                break;

            case ModalIDs.addUpdateHeader:
                updatedState = {
                    groupName: additionalInfo['groupName'] ?? ''
                }
                break;

            case ModalIDs.contractPrice:
                updatedState = this.state.adjustments![additionalInfo["index"]].value;
                break;

            case ModalIDs.dischargeFee:
            case ModalIDs.releaseFee:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        mortgages: 1,
                        each: 0,
                        status: "less",
                        state: this.state.matterDetails.state
                    };
                } else {
                    updatedState = this.state.adjustments![additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.recycledWater:
            case ModalIDs.waterUsage:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        method: "search-reading",
                        paidDate: null,
                        paidReadingAmount: 0,
                        searchDate: null,
                        searchReadingAmount: 0,

                        baseRateCostPerKL: 0,
                        baseRateKLCount: 0,
                        baseRateIncrease: 0,

                        tier2CostPerKL: 0,
                        tier2KLCount: 0,
                        tier2FeeIncrease: 0,

                        tier3CostPerKL: 0,
                        tier3KLCount: 0,
                        tier3FeeIncrease: 0,

                        adjustedBaseRateKLCount: 0,
                        adjustedTier2KLCount: 0,
                        adjustedTier3KLCount: 0,

                        balanceWaterCharges: 0,
                        balanceWaterChargesFeeIncrease: 0,

                        stateBulkWaterUsagesKLJune: 0,
                        stateBulkWaterUsagesKLJuly: 0,
                        stateBulkWaterCharges: 0,
                        stateBulkWaterFeeIncrease: 0,

                        averageDailyAmount: 0,
                        increasedFee: 0,
                        averageDailyAmountCharge: 0,
                        ctsOption: "do-not-apportion",
                        status: "less",
                        percentage: 0,
                        entitlementValue: ""
                    };
                } else {
                    updatedState = this.state.adjustments![additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.otherAdjustmentLegacy:
            case ModalIDs.otherAdjustment:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        description: "",
                        amount: 0,
                        status: "plus",
                    };
                } else {
                    updatedState = this.state.adjustments![additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.defaultInterest:
            case ModalIDs.penaltyInterest:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        rate: 0,
                        from: null,
                        to: null,
                        status: "paid",
                        billingPeriod: "",
                        adjustmentPeriod: "",
                    };
                } else {
                    updatedState = this.state.adjustments![additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.otherAdjustmentDateLegacy:
            case ModalIDs.otherAdjustmentDate:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        description: "",
                        amount: 0,
                        from: null,
                        to: null,
                        status: "paid",
                        billingPeriod: "",
                        adjustmentPeriod: "",
                    };
                } else {
                    updatedState = this.state.adjustments![additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.fee:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        description: "",
                        amount: 0,
                        status: "plus",
                        showOnAdjustment: false,
                    };
                } else {
                    updatedState = this.state.fees[additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.additionalRequirements:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        description: "",
                        amount: 0,
                        status: "plus",
                    };
                } else {
                    updatedState =
                        this.state.additionalRequirements[additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.payeeDetails:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        description: "",
                        amount: "0.00",
                        rowNumber: 0
                    };
                } else {
                    updatedState = this.state.payees[additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.sourceOfFunds:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        sourceOfFunds: "Pleaes Select",
                        amount: 0,
                    };
                } else {
                    updatedState = this.state.sourceOfFunds[additionalInfo["index"]].value;
                }
                break;

            case ModalIDs.ourRequirements:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        detail: "",
                    };
                } else {
                    updatedState =
                        this.state.ourRequirements[additionalInfo["index"]].value;
                }
                break;
            default:
                if (additionalInfo["index"] < 0) {
                    updatedState = {
                        method: "billed-amount",
                        amount: 0,
                        averageDailyAmount: 0,
                        increasedFee: 0,
                        billingPeriod: "",
                        adjustmentPeriod: "",
                        from: null,
                        to: null,
                        status: "paid",
                        ctsOption: "do-not-apportion",
                        percentage: 0,
                        entitlementValue: ""
                    };
                } else {
                    updatedState = this.state.adjustments![additionalInfo["index"]].value;
                }
                break;
        }
        this.updatedState = updatedState;
        if (this.updatedState['entitlementValue'])
            this.updatedState['entitlementValue'] = this.updatedState['entitlementValue'].toString();

        this.setState({
            showModal: modalID,
            selectedIndex: additionalInfo["index"],
        });
    }

    private updateAdjustmentDates(): void {
        let { matterDetails, adjustments } = this.state;

        let newAdjustments = adjustments!.map((adjustment) => {
            if (adjustment.value["adjustDays"]) {
                let adjustDays = this.getAdjustmentDays(adjustment.value["status"], matterDetails["adjustmentDate"].valueOf(), adjustment.value["from"], adjustment.value["to"]);

                return {
                    ...adjustment,
                    value: {
                        ...adjustment.value,
                        adjustDays,
                    },
                };
            }

            return adjustment;
        });

        this.setState({
            adjustments: newAdjustments,
        });
    }


    // by default, this savemodal function will save the data based on the state of this settlement calculator
    // however, since the modal is also now reused in other location the save function is adapted to work with that
    // 
    // the save function can also accept saving using external state if the modal is used from somewhere else
    // if no state or data is provided it'll use the default state
    //
    // modalID: id of the modal being saved
    // index: index value
    // data: state data to be saved
    // closedUponSave: if the modal is opened by an external caller, this should be set to false so the caller can manage closing the modal
    private async saveModal(modalID: (ModalIDs | null) = null, index: (number | null) = null, data: any = null, closeUponSave: boolean = true): Promise<any> {
        modalID = modalID === null ? this.state.showModal : modalID;
        index = index === null ? this.state.selectedIndex : index;
        data = data === null ? this.updatedState : data;

        switch (modalID) {
            case ModalIDs.matterDetails:
                if (
                    data["adjustmentDate"] === null ||
                    data["settlementDate"] === null
                ) {
                    this.setState({
                        dataInputError: errorMessages.dateInputError,
                    });
                    return;
                }

                data["adjustmentDate"] = convertStringToDate(data["adjustmentDate"].toISOString());
                data["settlementDate"] = convertStringToDate(data["settlementDate"].toISOString());

                await this.setState({
                    matterDetails: MatterDetails.fromJS(data),
                });

                this.updateAdjustmentDates();

                this.props.changeState(data["state"]);

                break;

            case ModalIDs.addAdjustment:
                let newModalID = data["itemType"];

                if (Object.values(ModalIDs).includes(newModalID)) {
                    this.showModal(newModalID, { index: -1 });
                }

                return;

            case ModalIDs.addUpdateHeader:
                if (!data['groupName']) {
                    this.setState({
                        dataInputError: errorMessages.headerNameRequiredError,
                    });
                    return;
                }

                let newAdjustments = [...this.state.adjustments!];
                let group = {
                    type: modalID,
                    groupName: data['groupName']
                }
                if (this.state.selectedIndex < 0) {
                    newAdjustments.push(group);
                } else {
                    newAdjustments[this.state.selectedIndex] = group;
                }

                this.setState({
                    adjustments: newAdjustments,
                })
                break;

            case ModalIDs.defaultInterest:
            case ModalIDs.penaltyInterest:
                if (data["from"] === null || data["to"] === null) {
                    this.setState({
                        dataInputError: errorMessages.dateInputError,
                    });
                    return;
                }
                else if (!showAdditionalFields(modalID) && (data["billingPeriod"] === "" || data["adjustmentPeriod"] == "")) {
                    this.setState({
                        dataInputError: errorMessages.adjustmentBillingPeriodError,
                    });
                    return;
                }

                data["from"] = convertStringToDate(data["from"].toISOString());
                data["to"] = convertStringToDate(data["to"].toISOString());
                data["gstApplicable"] = this.updatedState["gstApplicable"];

                let newDays = Math.floor((data["to"] - data["from"]) / 86400000) + 1;
                data["days"] = newDays > 0 ? newDays : 0;
                {
                    let newAdjustments = [...this.state.adjustments!];

                    if (this.state.selectedIndex < 0) {
                        let newAdjustment = {
                            value: Object.assign({}, data),
                            type: modalID,
                        };

                        newAdjustments.push(newAdjustment);
                    } else {
                        newAdjustments[this.state.selectedIndex].value = data;
                    }

                    this.setState({
                        adjustments: newAdjustments,
                    });
                }
                break;

            // case ModalIDs.recycledWater:
            //     if (data["paidDate"] === null || (data["method"] === "search-reading" && data["searchDate"] === null)) {
            //         this.setState({
            //             dataInputError: errorMessages.dateInputError,
            //         });
            //         return;
            //     }
            //     if (data["ctsOption"] === apportionmentOption.entitlement && (data["entitlementValue"] ? false : true)) {
            //         this.setState({
            //             dataInputError: errorMessages.entitlementValueFormatError,
            //         });
            //         return;
            //     }
            //     else if (data["ctsOption"] === apportionmentOption.entitlement && data["entitlementValue"].toString().split('/').length === 1) {
            //         this.setState({
            //             dataInputError: errorMessages.entitlementValueFormatError,
            //         });
            //         return;
            //     }

            //     else if (data["ctsOption"] === apportionmentOption.sharedPercentage && (data["percentage"] ? false : true)) {
            //         this.setState({
            //             dataInputError: errorMessages.sharedPercentageError,
            //         });
            //         return;
            //     }

            //     data["adjustedBaseRateKLCount"] = data["baseRateKLCount"];
            //     data["adjustedTier2KLCount"] = data["tier2KLCount"];
            //     data["adjustedTier3KLCount"] = data["tier3KLCount"];

            //     let adjustmentsRecycledWater = [...this.state.adjustments!];

            //     if (data["method"] === 'daily-average' && this.state.matterDetails.state === stateName.VIC) {
            //         data["searchDate"] = convertStringToDate(data["searchDate"].toISOString());
            //     }
            //     else {
            //         data["paidDate"] = convertStringToDate(data["paidDate"].toISOString());
            //     }
            //     if (data["method"] === 'search-reading') {
            //         data["searchDate"] = convertStringToDate(data["searchDate"].toISOString());
            //     }
            //     if (this.state.selectedIndex < 0) {
            //         let newAdjustment = {
            //             value: Object.assign({}, data),
            //             type: modalID,
            //         };
            //         adjustmentsRecycledWater.push(newAdjustment);
            //     } else {
            //         adjustmentsRecycledWater[this.state.selectedIndex].value = data;
            //     }

            //     this.setState({
            //         adjustments: adjustmentsRecycledWater,
            //     });
            //     break;

            case ModalIDs.recycledWater:
            case ModalIDs.waterUsage:
                if (data["method"] === 'search-reading') {
                    if (data["paidDate"] === null || data["searchDate"] === null) {
                        this.setState({
                            dataInputError: errorMessages.dateInputError,
                        });
                        return;
                    }
                }
                else if (data["method"] === 'daily-average') {
                    if (this.state.matterDetails.state === stateName.VIC) {
                        if (data["searchDate"] === null) {
                            this.setState({
                                dataInputError: errorMessages.dateInputError,
                            });
                            return;
                        }
                    }
                    else {
                        if (data["paidDate"] === null) {
                            this.setState({
                                dataInputError: errorMessages.dateInputError,
                            });
                            return;
                        }
                    }
                }
                if (data["ctsOption"] === apportionmentOption.entitlement && (data["entitlementValue"] ? false : true)) {
                    this.setState({
                        dataInputError: errorMessages.entitlementValueFormatError,
                    });
                    return;
                }
                else if (data["ctsOption"] === apportionmentOption.entitlement && data["entitlementValue"].toString().split('/').length === 1) {
                    this.setState({
                        dataInputError: errorMessages.entitlementValueFormatError,
                    });
                    return;
                }

                else if (data["ctsOption"] === apportionmentOption.sharedPercentage && (!!data["percentage"] ? false : true)) {
                    this.setState({
                        dataInputError: errorMessages.sharedPercentageError,
                    });
                    return;
                }

                data["adjustedBaseRateKLCount"] = data["baseRateKLCount"];
                data["adjustedTier2KLCount"] = data["tier2KLCount"];
                data["adjustedTier3KLCount"] = data["tier3KLCount"];

                let adjustmentsWater = [...this.state.adjustments!];

                if (data["method"] === 'daily-average' && this.state.matterDetails.state === stateName.VIC) {
                    data["searchDate"] = convertStringToDate(data["searchDate"].toISOString());
                }
                else {
                    data["paidDate"] = convertStringToDate(data["paidDate"].toISOString());
                }
                if (data["method"] === 'search-reading') {
                    data["searchDate"] = convertStringToDate(data["searchDate"].toISOString());
                }
                if (this.state.selectedIndex < 0) {
                    let newAdjustment = {
                        value: Object.assign({}, data),
                        type: modalID,
                    };
                    adjustmentsWater.push(newAdjustment);
                } else {
                    adjustmentsWater[this.state.selectedIndex].value = data;
                }

                this.setState({
                    adjustments: adjustmentsWater,
                });
                break;

            case ModalIDs.contractPrice:
            case ModalIDs.dischargeFee:
            case ModalIDs.releaseFee:
            case ModalIDs.otherAdjustmentLegacy:
            case ModalIDs.otherAdjustment:
                {
                    let newAdjustments = [...this.state.adjustments!];
                    data["gstApplicable"] = this.updatedState["gstApplicable"];

                    if (this.state.selectedIndex < 0) {
                        let newAdjustment = {
                            value: Object.assign({}, data),
                            type: modalID,
                        };

                        newAdjustments.push(newAdjustment);
                    } else {
                        newAdjustments[this.state.selectedIndex].value = data;
                    }

                    this.setState({
                        adjustments: newAdjustments,
                    });
                }
                break;

            case ModalIDs.fee:
                let fees = [...this.state.fees];

                if (this.state.selectedIndex < 0) {
                    let newFee = {
                        value: Object.assign({}, data),
                        type: modalID,
                    };

                    fees.push(newFee);
                } else {
                    fees[this.state.selectedIndex].value = data;
                }

                this.setState({
                    fees: fees,
                });

                break;

            case ModalIDs.additionalRequirements:
                let additionalRequirements = [...this.state.additionalRequirements];

                if (this.state.selectedIndex < 0) {
                    let additionalRequirement = {
                        value: Object.assign({}, data),
                        type: modalID,
                    };

                    additionalRequirements.push(additionalRequirement);
                } else {
                    additionalRequirements[this.state.selectedIndex].value =
                        data;
                }

                this.setState({
                    additionalRequirements: additionalRequirements,
                });

                break;

            case ModalIDs.payeeDetails:
                let payees = [...this.state.payees];

                if (this.state.selectedIndex < 0) {
                    let payee = {
                        value: Object.assign({}, data),
                        type: modalID,
                    };
                    payees.push(payee);
                } else {

                    payees[this.state.selectedIndex].value = data;
                }

                this.setState({
                    payees: payees,
                });

                break;

            case ModalIDs.sourceOfFunds:
                let funds = [...this.state.sourceOfFunds];

                if (this.state.selectedIndex < 0) {
                    let fund = {
                        value: Object.assign({}, data),
                        type: modalID,
                    };

                    funds.push(fund);
                } else {
                    funds[this.state.selectedIndex].value = data;
                }

                this.setState({
                    sourceOfFunds: funds,
                });

                break;

            case ModalIDs.ourRequirements:
                let ourRequirements = [...this.state.ourRequirements];

                if (this.state.selectedIndex < 0) {
                    let ourRequirement = {
                        value: Object.assign({}, data),
                        type: modalID,
                    };

                    ourRequirements.push(ourRequirement);
                } else {
                    ourRequirements[this.state.selectedIndex].value = data;
                }

                this.setState({
                    ourRequirements: ourRequirements,
                });

                break;

            case ModalIDs.rent:
            case ModalIDs.licenceFees:
                {
                    const validationError = validateModalData(data, modalID!);
                    if (!Tools.isNullOrEmptyOrUndefined(validationError)) {
                        this.setState({
                            dataInputError: validationError
                        });
                        return;
                    }

                    data["from"] = convertStringToDate(data["from"].toISOString());
                    data["to"] = convertStringToDate(data["to"].toISOString());

                    let dateDiff = getNumberOfDays(Math.abs(data["to"] - data["from"]));
                    let adjustDays = dateDiff;

                    if (data["adjustmentPeriod"] == periodOptions.Week) {
                        adjustDays = Math.round(dateDiff / 7);
                    } else if (data["adjustmentPeriod"] == periodOptions.Month) {
                        adjustDays = Math.round(dateDiff / 30);
                    }

                    let proportionDays = dateDiff + 1;
                    data["proportionDays"] = proportionDays;

                    data["adjustDays"] = adjustDays;
                    data["days"] = 1;
                    data["gstApplicable"] = this.updatedState["gstApplicable"];

                    let adjustments = [...this.state.adjustments!];

                    if (this.state.selectedIndex < 0) {
                        let newAdjustment = {
                            value: Object.assign({}, data),
                            type: modalID,
                        };

                        adjustments.push(newAdjustment);
                    } else {
                        adjustments[this.state.selectedIndex].value = data;
                    }

                    await this.setState({
                        adjustments: adjustments,
                    });
                    break;

                }

            default:
                {
                    const validationError = validateModalData(data, modalID!);
                    if (!Tools.isNullOrEmptyOrUndefined(validationError)) {
                        this.setState({
                            dataInputError: validationError
                        });
                        return;
                    }

                    data["from"] = convertStringToDate(data["from"].toISOString());
                    data["to"] = convertStringToDate(data["to"].toISOString());

                    let adjustDays = this.getAdjustmentDays(data["status"], this.state.matterDetails.adjustmentDate.valueOf(), data["from"], data["to"]);
                    let diffMs = Math.abs(data["to"] - data["from"]);

                    let days = data["adjustmentPeriod"] === periodOptions.Daily ? 1 : data["adjustmentPeriod"] === periodOptions.Week ? 7 : getNumberOfDays(diffMs) + 1;

                    let proportionDays = getNumberOfDays(diffMs) + 1;

                    data["adjustDays"] = adjustDays;
                    data["days"] = days;
                    data["proportionDays"] = proportionDays;
                    data["gstApplicable"] = this.updatedState["gstApplicable"];

                    let adjustments = [...this.state.adjustments!];

                    if (this.state.selectedIndex < 0) {
                        let newAdjustment = {
                            value: Object.assign({}, data),
                            type: modalID,
                        };

                        adjustments.push(newAdjustment);
                    } else {
                        adjustments[this.state.selectedIndex].value = data;
                    }

                    await this.setState({
                        adjustments: adjustments,
                    });
                    break;
                }
        }
        if (modalID != ModalIDs.addAdjustment.toString() && modalID != ModalIDs.addUpdateHeader.toString()) {
            this.save();
        }
        if (closeUponSave) {
            this.closeModal();
        }
    }

    private getAdjustmentDays(status: string, adjustmentDate: number, from: number, to: number) {
        let returnAdjustmentDays = 0;
        if (status === 'unpaid') {
            let diffMs = Math.abs(adjustmentDate - from)
            returnAdjustmentDays = getNumberOfDays(diffMs) + 1;
        }
        else if (status === 'paid' || status === 'adjust-as-paid') {
            let diffMs = Math.abs(to - adjustmentDate)
            // this one can be problematic cause it doesn't have the + 1 like above
            returnAdjustmentDays = getNumberOfDays(diffMs);
        }
        return returnAdjustmentDays;
    }
    private closeModal(): void {
        this.updatedState = {};

        this.setState({
            showModal: null,
            dataInputError: "",
            savePDFSuccess: false,
        });
    }

    // if this function is called within other functions that perform setState in it, the state would not have been updated by the time this function is called
    // hence the modified state should be passed in here for the function to work with instead
    //
    // if there is no state change in the caller function, this argument should not be necessary
    private save(modifiedState: any | undefined = undefined, isWaiting: boolean = true): void {
        const settlementData = generateUIData(modifiedState ?? this.state);
        const { matterInfo } = modifiedState ?? this.state;

        let payees = settlementData.payees?.filter((payee) => delete payee.isChecked)
        settlementData.payees = payees;

        const params: SettlementMatterViewModel = new SettlementMatterViewModel({
            actionstepOrg: matterInfo!.orgKey || "",
            matterId: matterInfo!.matterId || 0,
            version: 0,
            settlementData: settlementData,
            actionstepData: undefined,
            localDateTime: "",
            isAdvanced: this.props.useAdvancedCalculator
        });

        this.props.saveSettlementMatter(params);

        this.setState({
            isWaiting: isWaiting,
        });
    }

    private delete(): void {
        const { matterInfo } = this.state;

        this.props.deleteSettlementMatter(matterInfo!);

        this.setState({
            isWaiting: true,
        });
    }

    private generatePDF(isAdjustment = false, pdfAction: PdfAction = PdfAction.generatePdf): void {
        let {
            matterDetails,
            adjustments,
            fees,
            additionalRequirements,
            payees,
            sourceOfFunds,
            ourRequirements,
            additionalInfo,
            waterUsage,
        } = generateUIData(this.state);

        adjustments =
            adjustments &&
            adjustments.map((adjustment, index) => {
                let newAdjustment = {
                    ...adjustment,
                    ...adjustment["value"],
                };

                delete newAdjustment.value;

                return newAdjustment;
            });

        adjustments =
            adjustments &&
            adjustments.map((item, index) => {
                item["status"] = (item["status"] === "unpaid" || item["status"] === "less") ? "Less" : item["status"] === "adjust-as-paid" ? "Adjust as Paid" : "Plus";
                return item;
            });

        fees =
            fees &&
            fees.map((item, index) => {
                let newItem = {
                    ...item,
                    ...item.value,
                };

                delete newItem.value;

                return newItem;
            });

        additionalRequirements =
            additionalRequirements &&
            additionalRequirements.map((item, index) => {
                let newItem = {
                    ...item,
                    ...item["value"],
                };

                delete newItem.value;

                return newItem;
            });

        payees =
            payees &&
            payees.map((item, index) => {
                let newItem = {
                    ...item,
                    ...item["value"],
                };

                delete newItem.value;

                return newItem;
            });


        sourceOfFunds =
            sourceOfFunds &&
            sourceOfFunds.map((item, index) => {
                let newItem = {
                    ...item,
                    ...item["value"],
                };

                delete newItem.value;

                return newItem;
            });

        ourRequirements =
            ourRequirements &&
            ourRequirements.map((item, index) => {
                let newItem = {
                    ...item,
                    ...item["value"],
                };

                delete newItem.value;

                return newItem;
            });

        additionalInfo!["isAdjustment"] = isAdjustment;

        let settlementData: SettlementInfoViewModel = new SettlementInfoViewModel({
            matterDetails: new MatterDetails(matterDetails),
            adjustments,
            fees,
            additionalRequirements,
            payees,
            sourceOfFunds,
            ourRequirements,
            waterUsage,
            additionalInfo
        });

        const { matterInfo } = this.state;

        var dateValue = new Date();
        var localDateTile = formatToLocaleDateTimeString(dateValue);

        const params: SettlementMatterViewModel = new SettlementMatterViewModel({
            actionstepOrg: matterInfo!.orgKey || "",
            matterId: matterInfo!.matterId || 0,
            version: 0,
            settlementData: settlementData,
            localDateTime: localDateTile,
            isAdvanced: this.props.useAdvancedCalculator
        });

        if (pdfAction === PdfAction.savePdfToActionstep) {
            this.props.savePDF(params);
        } else if (pdfAction === PdfAction.generatePdf) {
            this.props.generatePDF(params);
        } else if (pdfAction === PdfAction.generateStatementOfAccount) {
            this.props.generateStatementOfAccount(params);
        }

        this.setState({
            isWaiting: true,
        });
    }

    private sendEmail(): void {
        let uiData = generateUIData(this.state);

        let emailContent =
            this.state.matterDetails.matter +
            "%0AProperty: " +
            this.state.matterDetails.property +
            "%0A%0A";

        emailContent +=
            `Dear Sir/Madam %0A%0AWe confirm settlement of the above matter has been booked for ` +
            this.state.matterDetails.settlementTime +
            ` on ` +
            this.state.matterDetails.settlementDate.toDateString() +
            " at the office of " +
            this.state.matterDetails.settlementPlace +
            ".%0A";

        emailContent += `We hereby direct cheques to be made payable as follows:%0A%0A`;

        let totalAmount = 0;
        let listIndex = 0;
        if (uiData.payees !== undefined && uiData.payees?.length > 0) {
            for (var index = 0; uiData.payees?.length > index; index++) {
                if (uiData.payees[index].isChecked === true) {
                    listIndex++;
                    if (!isNaN(uiData.payees[index].value["amount"])) {
                        totalAmount += parseFloat(uiData.payees[index].value["amount"]);
                        emailContent += listIndex + ". " + uiData.payees[index].value["description"] + "       $" + uiData.payees[index].value["amount"] + "%0A";
                    }
                    else
                        emailContent += listIndex + ". " + uiData.payees[index].value["description"] + "       " + uiData.payees[index].value["amount"] + "%0A";
                }
            }
        }

        if (uiData.additionalRequirements !== undefined && uiData.additionalRequirements?.length > 0) {
            for (var index = 0; uiData.additionalRequirements?.length > index; index++) {
                if (uiData.additionalRequirements[index].isChecked === true) {
                    listIndex++;
                    if (!isNaN(uiData.additionalRequirements[index].value["amount"])) {
                        totalAmount += parseFloat(uiData.additionalRequirements[index].value["amount"]);
                        emailContent += listIndex + ". " + uiData.additionalRequirements[index].value["description"] + "       $" + uiData.additionalRequirements[index].value["amount"] + "%0A";
                    }
                    else
                        emailContent += listIndex + ". " + uiData.additionalRequirements[index].value["description"] + "       " + uiData.additionalRequirements[index].value["amount"] + "%0A";
                }
            }
        }

        emailContent += totalAmount > 0 ? `%0A` : "";

        emailContent +=
            "The total of the cheque above is $" +
            (toFormatLocalString(totalAmount, "en-AU", 2, 2) || 0) + `.`;

        emailContent += `%0A%0ARegards.%0A`;

        emailContent = emailContent.replace(new RegExp(escapeRegExp('&'), 'g'), `%26`);
        let mailToLink =
            "mailto:" +
            this.state.lc +
            "?subject=Settlement Cheque Details - Matter Ref#" +
            this.state.matterDetails.matterRef +
            "&body=" + emailContent;
        window.location.href = mailToLink;
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        state: state.settlementInfo.state,
        actionstepContext: state.common.actionstepContext,
        success: state.settlementInfo.success,
        gotResponse: state.settlementInfo.gotResponse,
        settlementMatter: state.settlementInfo.settlementMatter,
        actionstepPDF: state.settlementInfo.actionstepPDF,
        requestType: state.settlementInfo.requestType,
        error: state.settlementInfo.error,
        stateOfCountry: state.settlementInfo.state,
        useAdvancedCalculator: true,
    };
};

const mapDispatchToProps = {
    generatePDF,
    generateStatementOfAccount,
    savePDF,
    getSettlementMatter,
    saveSettlementMatter,
    deleteSettlementMatter,
    changeState,
    clearSettlementState
};

export default connect(mapStateToProps, mapDispatchToProps)
    (SettlementCalculator);